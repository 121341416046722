.section_background {
    position: absolute;
    background-color: var(--color-complementary-two);
    width: 100%;
    -webkit-transition-property: height top;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: height top;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.section_background_landingpage {
    background-image: url('./../../assets/images/bkg-tile-pattern-gray.png');
    overflow-x: hidden;
}

.section_frontground {
    margin: 0px auto;
    /* hack */
    opacity: 0.999;
    position: relative;
    /* max-width: 700px; */
}

.content-top-bg {
    height: 10rem;
    margin-bottom: -0.5rem;

    background-image: url('./../../assets/images/bg-content-top.png');
    background-position: center;
    background-size: 1024px 10rem;
    background-repeat: no-repeat;
}


.content-wrapper {
    background-image: url('./../../assets/images/bg-content-bototm.png');
    background-position: bottom;
    background-size: 1024px auto;
    background-repeat: repeat-y;
}

.section_backgrounds {
    background-image: url('./../../assets/images/bg-app-01.png');
    background-position: top;
    background-size: 1024px;
    background-repeat: no-repeat;
}

.footer-wrapper {
    padding-top: 30rem;
    background-image: url('./../../assets/images/bg-app-02.png');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}

.section_bgColor {
    background-color: var(--color-background-main);
}

.top-empty-space {
    height: 20rem;
}