.fale_conosco_container  {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.fale_conosco_container input  {
    cursor: pointer;
    color:black;
    background-color:white;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0.625rem;
    border-color: #6D6E71;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.fale_conosco_container textarea  {
    cursor: pointer;
    color: black;
    background-color:white;
    width: 100%;
    height: 150px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 12px;
    border-color: #6D6E71;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.fale_conosco_container span {
    color:red;
    font-size: 12px;
    margin-right: auto;
}