.create_account_section {
  margin-top: 2rem;
  font-size: 16px;
  color: var(--color-white);
}

.create_account_section .registration_link {
  margin-top: -5px;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.auth_login_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth_login_message {
  margin-top: 23px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 13px;
  color: var(--color-text-primary);
}

.auth_login_error {
  font-size: 14px;
  color: var(--color-alert-primary);
  margin-top: -15px;
  align-self: flex-start;
}

.auth_login_container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.auth_login_container input {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-color: var(--color-text-primary);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
  box-sizing: border-box;
}

.auth_login_container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-primary);
  opacity: 1; /* Firefox */
}

.auth_login_container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-primary);
}

.auth_login_container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-primary);
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
}

.checkbox-wrapper p {
  font-size: initial;
  padding-left: 20px;
}
.checkbox-wrapper img {
  padding-left: 20px;
  width: 45px;
  height: 45px;
}

.checkbox {
  width: 8px;
  height: 8px;
  border: solid 7px white;
  border-radius: 20px;
  align-self: center;
  cursor: pointer;
}

.auth_method_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  max-width: 400px;
  width: 100%;
  overflow-y: scroll;
  padding: 10px 0 0 0;
  max-height: 300px;
  scroll-snap-type: y mandatory;
}

.auth_method_list::-webkit-scrollbar {
  width: 20px; /* width of the entire scrollbar */
}

.auth_method_list::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list_loading {
  height: 250px;
}

.auth-method-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth_method_title {
  font-family: "Intro Bold";
  color: var(--color-text-primary);
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18px;
  line-height: 22px;
}

.auth_method_item {
  display: flex;
  padding: 0 14px;
  width: 80%;
  min-width: 270px;
  margin-bottom: 15px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 10px;
  background-color: var(--color-white);
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  cursor: pointer;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.auth_method_header{
  display: flex;
  padding: 0 14px;
  width: 100%;
  min-width: 270px;
  margin-bottom: 15px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 10px;
  background-color: var(--color-white);
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  cursor: pointer;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.auth_method_item_infos {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.auth_method_item_infos span {
  color: var(--color-secondary);
  font-size: 12px;
  font-family: "Intro Regular";
  font-weight: 300;
  text-transform: uppercase;
  padding: 0;
}

.auth_method_item_infos img {
  height: 50px;
  margin-right: 10px;
}

.WelcomeButton {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 18px;
  color: var(--color-text-primary);
  background-color: var(--color-primary);
  margin: 40px 0 40px 0;
  padding: 18px;
  width: 60%;
  max-width: 400px;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 74%);
}

.RegulationButton {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 15px;
  color: var(--color-text-primary);
  background-color: transparent;
  border: none;
}

.button-help {
  cursor: pointer;
}

.auth_login_button_other {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 18px;
  max-width: 340px;
  width: 40%;
  margin-top: 4px;
  color: var(--color-text-primary);
  margin-bottom: 20px;
  border-color: var(--color-text-primary);
  padding: 14px;
  background-color: #ffffff00;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.inputWrapper {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.auth_method_contact {
  max-width: 200px;
  width: 45%;
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .auth_method_contact {
    margin-top: 20px;
  }
}

.auth_method_contact_button {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 18px;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 14px;
  border-color: var(--color-text-primary);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  margin-bottom: 0;
}

.auth_login_button_login {
  font-family: "Intro Bold";
  cursor: pointer;
  font-size: 18px;
  max-width: 400px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
  border-color: var(--color-text-primary);
  padding: 14px;
  border-radius: 4px;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  border-width: 1px;
  border-style: solid;
}

.auth-page-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

.plans_wrapper {
  width: 100%;

  display: flex;
  max-width: 400px;

  gap: 16px;
  margin-bottom: 32px;
}

.plan_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
  background-color: #F2F2F2;
  border-radius: 8px;

  font-size: 14px;

  border-width: 2px;
  border-style: solid;
  border-color: var(--color-white);
}


.selected {
  border-color: var(--color-primary) !important;
}

/* .plan_wrapper + .plan_wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
} */

.benefits {
  width: 100%;
  text-align: left;
}

.benefits_title {
  font-size: 16px;
}

.benefits ul {
  list-style-position: inside;
}

.benefits ul li {
  text-indent: -32px;
}

.plan_book_month {
  margin-bottom: -8px;
}

.plan_price_wrapper {
  font-size: 26px;
}


.benefits p + p {
  margin-top: -8px;
}

.plan_wrapper button {
  width: 100%;
  max-width: 145px;
  border: 0;
  border-radius: 2rem;
  height: 45px;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.plan_wrapper .disabled {
  opacity: 0.6;
}