
.main_title {
    padding-block-end: 1.5em;
    padding-left: 20px;
    padding-right: 20px;
}

.main_title_text {
    position: relative;
    font-family: "Intro Bold";
    color: var(--color-yellow);
    margin-block-start: 0;
    margin-block-end: 10px;
    line-height: 1.3em;
    width: var(--number-body-default-width);
    margin: 0px auto;
    font-size: 17px;
}

.main_title_subtext {
    font-family: "Intro Regular";
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.3em;
    width: var(--number-body-default-width);
    margin: 0px auto;
}
.main_title_availability {
    font-family: "Intro Regular";
    font-size: 10px;
    color: var(--color-text-primary);
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.3em;
    width: var(--number-body-default-width);
    margin: 0px auto;
}

