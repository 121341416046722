.review_container {
    opacity: 0;
    margin-top: 2rem;
}

/* Style the buttons that are used to open and close the review panel */

.review {
    /* cursor: pointer; */
    display: flex;
    align-self: center;
    align-items: baseline;
    border: none;
    outline: none;
}

.review .review__image {
    margin-top: 10px;
    transform: scale(0.85);
    width: 140px;
    /* cursor: pointer; */
}

/* Style the review section */

.review__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 100vh !important; */
}

.review__section .review {
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: rgba(0, 0, 0, 0) !important;
}

/* Style the review content panel. Note: hidden by default */

.review__content {
    overflow: hidden;
    /* max-height: auto; */
    transition: max-height .6s ease;
}

.review__content p {
    font-family: "Intro Regular";
    line-height: 1.2em;
    padding-left: 2.2em;
    padding-right: 2.2em;
    width: var(--number-body-default-width);
    margin: 0px auto;
}

/* Style the review content text */

.review__text {
    font-size: 16px;
    padding-bottom: 1em;
}

/* Style the review content text */

.review__author_title {
    color: var(--color-yellow);
    font-size: 16px;
}

/* Style the review content text */

.review__author_desc {
    color: var(--color-yellow);
    font-size: 14px;
    padding-bottom: 2em;
}

/* Style the review icon */

.review__icon {
    margin-left: auto;
    padding-bottom: 6px;
    padding-left: 0px;
    width: 20px;
    height: 20px;
    transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */

.rotate {
    padding-bottom: 6px;
    transform: rotate(45deg);
}

.review__button_close {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 12px;
    color: var(--color-text-primary);
    background-color: #FFFFFF00;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 2em;
    padding-top: 8px;
    padding-bottom: 6px;
    padding-left: 2em;
    padding-right: 2em;
    border-color: var(--color-text-primary);
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}
