
.carrossel_container {
    height: var(--number-carrossel-default-height);
    width: 260px;
    padding: 12px 0 12px 0;
    display: inline-flex;
    flex-direction: column;
    /* background-color: blueviolet; */
}

.carrossel_wrap {
    opacity: 0;
    /* width: var(--number-body-default-width); */
}

.carrossel_container .slider .slider-frame {
    overflow: visible!important;
}

.carrossel_slide {
    width: 204px;
    height: 204px;
    margin-top: 15px;
    margin-left: 15px;
    opacity: 1;
    display: inline-flex;
    background-color: var(--color-secondary);
    /* background-image: none !important; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.slide-visible .carrossel_slide {
    width: 257px;
    height: 257px;
    margin-top: 0px;
    margin-left: 0px;
    opacity: 1;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.2);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    
}
li.slider-slide.slide-visible{
    
    z-index: 1;
} 

 @media (max-width: 420px) 
{
    .carrossel_wrap{
        width: 5em;
       
    }
}

li:focus{
    outline:none;
}

.boat_wrap{
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-complementary-two);
    vertical-align: middle;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 50;
}

.boat_animation {
    width: 30rem;
}

@media screen and (max-width: 450px) and (orientation: portrait) {
    .boat_animation {
        width: 20rem;
    }
}

@media screen and (max-width: 914px) and (orientation: landscape) {
    .boat_animation {
        width: 20rem;
    }
}

/* .boat_animation{
    width: 100%;
    height: auto;  
} */

.boat_none{
    display: none
}

/* @media (min-width: 768px) 
{
    .boat_wrap{
        position: absolute;
        width: 20em;
        top: 5.6em;
    }
} */