

.book_detail_container {
    opacity: 0;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.book_detail_infos {
    height: 6rem;
    font-family: "Intro Regular";
    font-size: 19px;
    margin-block-start: 0.2em;
    margin-block-end: 0em;
    padding-left: 2em;
    padding-right: 2em;
    white-space: pre-wrap;
    line-height: 1.3em;
    margin-top: 16px;
}

.book_detail_infos p + p {
    font-size: 15px;
}

.text-red {
    color: var(--color-primary);
}

.book_detail_container button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 13px;
    width: 100%;
    height: 45px;
    display: flex !important;
    flex-grow: 0;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    color: var(--color-complementary-two);
    background-color: var(--color-yellow);
    margin-top: 16px;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 2em;
    padding-right: 2em;
    border-color: var(--color-text-primary);
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;
}

.book_detail_container .secondary {
    width: 50%;
    margin: 16px auto 0px auto;
    background-color: var(--color-white);
    border-color: var(--color-complementary-two);
    color: var(--color-complementary-two);
}

.download {
    padding-top: 24px;
}

.book_detail_container :disabled {
    opacity: 0.5;
}

.book_detail_link {
    font-family: "Intro Regular";
    font-size: 12px;
    color: var(--color-complementary-two);
}
